import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center creator-content-thank-you-page__container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex flex-wrap justify-center gap-x-8 gap-y-4 mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "creator-content-thank-you-page" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.graphicSrc,
          alt: "Thank You",
          class: "creator-content-thank-you-page__image max-w-full"
        }, null, 8, _hoisted_2),
        _createVNode(_component_a_typography_title, {
          level: 2,
          class: "my-14 leading-none"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Thank you!")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_title, {
          level: 5,
          class: "leading-none"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("How to Pay")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, { class: "mt-6" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("We will reach out via email with the payment link.")), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_button, {
            type: "secondary",
            class: "bg-transparent",
            onClick: _ctx.redirectToCreatorContentSearch
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("View More Contents")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.redirectToHomepage
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Order Custom Video")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}