
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { config } from "@/shared/utils/config";
import routeNames from "@/web/router/routeNames";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import graphicSrc from "@/assets/graphics/creator-content-thank-you.png";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();

    const { redirectToHomepage, redirectToCreatorContentSearch } =
      useRedirectHelper();

    return {
      t,
      config,
      routeNames,
      graphicSrc,
      redirectToHomepage,
      redirectToCreatorContentSearch,
    };
  },
  components: {
    PageLayout,
  },
});
